import { CascadeSelects } from "../../lib/cascaded_selects"

const DISPOSITION_STATUS = {
  CascadeSelectorContainerId: "disposition_status_container",
  CascadeSelectorName: "case[disposition_status]",
  CaseSelectorId: "case_disposition_status",
  CaseInputLabel: "Disposition Status"
}

const DISPOSITION_TYPE = {
  CascadeSelectorContainerId: "disposition_type_container",
  CascadeSelectorName: "case[disposition_type]",
  CaseSelectorId: "case_disposition_type",
  CaseInputLabel: "Disposition Type"
}

const DISPOSITION_REASON = {
  CascadeSelectorContainerId: "disposition_reason_container",
  CascadeSelectorName: "case[disposition_reason]",
  CaseSelectorId: "case_disposition_reason",
  CaseInputLabel: "Disposition Reason"
}

const CASCADE_LEVELS = [DISPOSITION_STATUS, DISPOSITION_TYPE, DISPOSITION_REASON]

const CASCADE_LIST = {
  "Arbitration Award": { Title: "Arbitration Award" },
  "Full Agreement": { Title: "Full Agreement" },
  "No agreement": { Title: "No Agreement" },
  "No Show – Both Parties": { Title: "No Show - Both Parties" },
  "No show – Defendant": { Title: "No Show - Defendant" },
  "No show – Plaintiff": { Title: "No Show - Plaintiff" },
  "Partial Agreement": { Title: "Partial Agreement" },
  "Partial and Temporary Agreement": { Title: "Partial and Temporary Agreement" },
  "Reconvene": { Title: "Reconvene" },
  "Temporary agreement": { Title: "Temporary Agreement" }
}

const AtAdr = {
  Title: "At ADR",
  Cascade: CASCADE_LIST, ...DISPOSITION_TYPE
}

const DismissedSettledAfterAdr = {
  Title: "Dismissed/Settled After ADR",
  Cascade: CASCADE_LIST
}

const DismissedSettledBeforeAdr = {
  Title: "Dismissed/Settled Before ADR",
  Cascade: CASCADE_LIST
}

const ExemptRemoved = {
  Title: "Exempt/Removed",
  Cascade: {
    "Change of Venue": {
      Title: "Change of Venue"
    },
    "Contempt Only": {
      Title: "Contempt Only"
    },
    "Could not locate": {
      Title: "Could not Locate"
    },
    "Domestic Violence": {
      Title: "Domestic Violence"
    },
    "Incapacitated Party": {
      Title: "Incapacitated Party"
    },
    "Incarcerated Party": {
      Title: "Incarcerated Party"
    },
    "Judge Waived": {
      Title: "Judge Waived"
    },
    "Noncompliance": {
      Title: "Noncompliance"
    },
    "Reconciled": {
      Title: "Reconciled"
    },
    "Refused Mail": {
      Title: "Refused Mail"
    },
    "Unable to schedule": {
      Title: "Unable to Schedule"
    }
  },  ...DISPOSITION_REASON
}

const SelectedPrivateNeutral = {
  ...AtAdr,
  Title: " Selected Private Neutral",
}

const renderCascade = (elemConfig, rootForm) => {
  const rawHtml = `<div class="form-group select required case_disposition_status">
    <label class="select required" for="case_disposition_status">
      ${elemConfig.CaseInputLabel}
    </label>
    <select class="select required form-control" aria-required="true" name="${elemConfig.CascadeSelectorName}" id="${elemConfig.CaseSelectorId}">
    </select>
  </div>`

  const template = document.createElement('template');
  template.innerHTML = rawHtml.trim();

  const cascadeNodeContainer = rootForm ? rootForm.find(`#${elemConfig.CascadeSelectorContainerId}`)[0] : document.getElementById(elemConfig.CascadeSelectorContainerId);
  const node = template.content.firstChild;

  while (cascadeNodeContainer.firstChild) {
    cascadeNodeContainer.removeChild(cascadeNodeContainer.firstChild);
  }

  cascadeNodeContainer.append(node)

  const selectNode = rootForm ? rootForm.find(`#${elemConfig.CaseSelectorId}`)[0] : document.getElementById(elemConfig.CaseSelectorId);

  return selectNode;
}

const Main = {
  CascadeLevels: CASCADE_LEVELS,
  renderCascade: renderCascade,
  Cascade: {
    "At ADR": AtAdr,
    "Dismissed/Settled after ADR": DismissedSettledAfterAdr,
    "Dismissed/Settled before ADR": DismissedSettledBeforeAdr,
    "Exempt/Removed": ExemptRemoved,
    "Selected private neutral": SelectedPrivateNeutral
  }
}

window.initializedCaseDispositionCascadeFields = [];

window.toggleDropDown = function(selectedValues) {
  CascadeSelects(Main, "case_disposition_status", selectedValues)
}

window.showHideDispositionButton = function(e) {
  let reason_parent = $('#disposition_reason_container').parent()
  let type_parent = $('#disposition_type_container').parent()
  let disposition_status = $('#case_disposition_status').val()
  if(disposition_status === "At ADR" || disposition_status === "Selected private neutral") {
    reason_parent.addClass('d-none')
    type_parent.removeClass('d-none')
  } else if(disposition_status === "Exempt/Removed") {
    type_parent.addClass('d-none')
    reason_parent.removeClass('d-none')
  } else {
    reason_parent.addClass('d-none')
    type_parent.addClass('d-none')
  }
}

window.initCaseDispositionCascadeFields = function(selectedValues) {
  toggleDropDown(selectedValues)
  showHideDispositionButton();
  $('#disposition_status_container').change(function(e) {
    showHideDispositionButton(e);
    toggleDropDown(selectedValues);
  });
}

window.initCaseDispositionSelect = function() {
  $('#case_disposition_status').on("change", function(e) {
    showHideDispositionButton(e);
  });
}

window.showHideCheckboxAndLabel = function(mainCheckboxId, plaintiffCheckboxId, defendantCheckboxId, plaintiffCheckboxLabelClass, defendantCheckboxLabelClass, mainCheckbox, plaintiffSelectInputName, defendantSelectInputNanme, plaintiffDefendantDetails, plaintiffSelectId, defendantSelectId, plaintiffData, defendantData) {
  $(mainCheckboxId).on("change", function(e){
    if($(mainCheckboxId).prop('checked') == true){
      $(`${plaintiffCheckboxId}, ${defendantCheckboxId}, ${plaintiffCheckboxLabelClass}, ${defendantCheckboxLabelClass}`).removeClass('d-none');
    }
    else{
      $(`${plaintiffCheckboxId}, ${defendantCheckboxId}, ${plaintiffCheckboxLabelClass}, ${defendantCheckboxLabelClass}`).addClass('d-none');
      e.target.parentElement.querySelectorAll("input").forEach(element => {
        if( element.id != mainCheckbox){
          if(element.name != `case[${plaintiffSelectInputName}][]` && element.name != `case[${defendantSelectInputNanme}][]` && element.type != "hidden" && element.type != "checkbox"){
            element.name = `case[${plaintiffDefendantDetails}][p]`;
            element.value = "";
          }
          element.classList.add("d-none");
          if(element.parentElement.firstElementChild.className != "labels"){
            element.parentElement.firstElementChild.classList.add("d-none");
          }
          $(`${plaintiffCheckboxId}, ${defendantCheckboxId}`).prop('checked', false)
          $(`${plaintiffSelectId}, ${defendantSelectId}`).parent().addClass('d-none');
          $(`${plaintiffData}, ${defendantData}`).remove();
         $(`${plaintiffSelectId}, ${defendantSelectId}`).val(null).trigger('change');
        }
      });
    }
  });
}

window.setDateFunction = function(){
  var dates = $('[id^="for_r_r_date_of_"], [id^="for_date_of_"]');
  for (var i = 0; i < dates.length; i++){
   var date_id = $.escapeSelector(dates[i].id)
   singleDatePicker(date_id);
  }
}

window.checkboxs = function(){
  if($("#case_fees_owed_plaintiff").prop('checked') == false) {
    $("#Plaintiff").parent().addClass('d-none');
  }
  if($("#case_fees_owed_defendant").prop('checked') == false) {
    $("#Defendant").parent().addClass('d-none');
  }
  if($("#case_recant_received_plaintiff").prop('checked') == false) {
    $("#recant_received_Plaintiff").parent().addClass('d-none');
  }
  if($("#case_recant_received_defendant").prop('checked') == false) {
    $("#recant_received_Defendant").parent().addClass('d-none');
  }
}

window.onclickFunction =  function(name, pd, case_id){
  var user_name = name.replace(/ /g,"_")
  var date = $('#Date_for_'+ user_name).val();
  var amount_owned = $('#Amount_for_' + user_name).val();
  var total_amount_owned = $('.total_amount_' + user_name).val();
  var fees_plaintiff_or_diffendant = $('.add_btn_'+user_name).closest('.checkbox').children()[1].value
  var fees_owed_or_recent_received = $('.add_btn_'+user_name).closest('.main_checkbox').children()[1].value
  var fees_owed_to = $('#Fees_Owed_To_' + user_name).val();
  let initial_payment;
  if (total_amount_owned == "0.00"){
    initial_payment = "true";
  }
  else{
    initial_payment = "false";
  }
  $.ajax({
    headers: {
      'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
      'X-Turbolinks-Nonce': $("script")[0].nonce
    },
    method: "POST",
    url: "/tenant/dashboard/cases/" + case_id + '/open_pay_party',
    dataType: 'script',
    data: {
      name: user_name,
      pd: pd,
      case_id: case_id,
      amount_owned: amount_owned,
      fees_owed_to: fees_owed_to,
      date: date,
      initial_payment: initial_payment,
      fees_owed_by: fees_owed_or_recent_received,
      fees_owed: fees_plaintiff_or_diffendant,

    }
  });
}
